#main-nav {
  display: none;
  margin-top: 0;
}

#mobile-nav {
  width: 100%!important;
  display: flex;
  align-items: center;
  padding: 15px 20px;
  background: #2c3e50;
  border-bottom: 11px solid #18BC9C !important;
}

#mobile-nav .bars.icon {
  margin-left: auto;
  color: white;
 }

 #mobile-nav .menu {
   width: 100%;
   user-select: none;
 }
@media (min-width: 768px) {
  #mobile-nav {
    display: none;
  }
  #main-nav {
    display: flex;
  }
}