
.rbc-btn {
    color: inherit;
    font: inherit;
    margin: 0
}
button.rbc-btn {
    overflow: visible;
    text-transform: none;
    -webkit-appearance: button;
    cursor: pointer
}
button[disabled].rbc-btn {
    cursor: not-allowed
}
button.rbc-input::-moz-focus-inner {
border:0;
padding:0
}
.rbc-calendar {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    height: 100%;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex-align: stretch;
    align-items: stretch
}
.rbc-calendar *, .rbc-calendar :after, .rbc-calendar :before {
    -webkit-box-sizing: inherit;
    box-sizing: inherit
}
.rbc-abs-full, .rbc-row-bg {
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0
}
.rbc-ellipsis, .rbc-event-label, .rbc-row-segment .rbc-event-content, .rbc-show-more {
    display: block;
    overflow: hidden;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
    white-space: nowrap
}
.rbc-rtl {
    direction: rtl
}
.rbc-off-range {
    color: #999
}
.rbc-off-range-bg {
    background: #e5e5e5
}
.rbc-header {
    overflow: hidden;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
    white-space: nowrap;
    vertical-align: middle;
    font-size: 1.2rem;
    min-height: 0;
    padding: .5rem 1rem;
}
.rbc-header>a, .rbc-header>a:active, .rbc-header>a:visited {
    color: inherit;
    text-decoration: none
}
.rbc-row-content {
    position: relative;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-user-select: none;
    z-index: 4
}
.rbc-month-row .rbc-row-content {
    min-height: 125px;
}
.rbc-today {
    background-color: #fff;
}
.rbc-header.rbc-today {
    background-color: #fff;
}
.rbc-toolbar {
    display: -ms-flexbox;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 10px;
    font-size: 16px
}
.rbc-toolbar .rbc-toolbar-label {
    -ms-flex-positive: 1;
    flex-grow: 1;
    padding: 10px 0;
    text-align: center
}
.rbc-toolbar button {
    color: #373a3c;
    display: inline-block;
    margin: 7px 0px;
    text-align: center;
    vertical-align: middle;
    background-image: none;
    background: transparent;
    border-top: 0;
    border-bottom: 0;
    border-left: 1px solid #ddd;
    border-right: 0;
    padding: 6px 30px;
    border-radius: 0;
    line-height: normal;
    white-space: nowrap;
    font-size: .9rem;
    text-transform: CAPITALIZE;
}
.rbc-toolbar button.rbc-active, .rbc-toolbar button:active {
    font-weight: 700;
}
.rbc-toolbar button.rbc-active:hover, .rbc-toolbar button:active:hover {
    color: #373a3c;
    background-color: #d4d4d4;
}
.rbc-toolbar button:hover {
    background-color: #e6e6e6;
}
.rbc-toolbar .rbc-btn-group:last-child button + button + button:not(:last-child) {
    display: none;
}
@media (min-width: 768px) {
    .rbc-toolbar {
        flex-direction: row;
        align-items: center;
    }
    .rbc-toolbar .rbc-toolbar-label {
        padding: 0 10px;
    }
    .rbc-toolbar .rbc-btn-group:last-child button {
        display: inline-block !important;
    }
}
.rbc-btn-group {
    display: inline-block;
    white-space: nowrap
}
.rbc-btn-group>button:focus {
    outline: none;
}
.rbc-btn-group>button {
    user-select: none;
    cursor: pointer;
}
.rbc-btn-group>button:first-child:not(:last-child) {
  border-left: 0;
}
.rbc-btn-group>button:last-child:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0
}
.rbc-rtl .rbc-btn-group>button:first-child:not(:last-child) {
    border-radius: 4px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0
}
.rbc-rtl .rbc-btn-group>button:last-child:not(:first-child) {
    border-radius: 4px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0
}
.rbc-btn-group>button:not(:first-child):not(:last-child) {
    border-radius: 0
}
.rbc-btn-group button+button {
    margin-left: -1px
}
.rbc-rtl .rbc-btn-group button+button {
    margin-left: 0;
    margin-right: -1px
}
.rbc-btn-group+.rbc-btn-group, .rbc-btn-group+button {
    margin-left: 10px
}
.rbc-event {
    cursor: pointer;
    border-left: .3125rem solid #18bc9c;
    display: -ms-flexbox;
    display: flex;
    max-height: 100%;
    -ms-flex-flow: column wrap;
    flex-flow: column wrap;
    -ms-flex-align: start;
    align-items: flex-start;
    overflow: hidden;
    border-bottom: 0;
    border-top: 0;
    border-right: 0;
    height: 100%;
    background: #eafcf9;
    border-radius: 0;
    color: rgba(0,0,0,.6);
    padding: .2rem 1rem .2rem .6875rem;
    font-size: 1rem;
}
.rbc-event.google {
    background: #ddfcff
}
.rbc-event.rbc-selected {
    background-color: #265985
}
.rbc-event-label {
    font-size: 80%
}
.rbc-event-overlaps {
    -webkit-box-shadow: -1px 1px 5px 0 rgba(51,51,51,.5);
    box-shadow: -1px 1px 5px 0 rgba(51,51,51,.5)
}
.rbc-event-continues-prior {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0
}
.rbc-event-continues-after {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0
}
.rbc-event-continues-earlier {
    border-top-left-radius: 0;
    border-top-right-radius: 0
}
.rbc-event-continues-day-after, .rbc-event-continues-later {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0
}
.rbc-event-continues-day-prior {
    border-top-left-radius: 0;
    border-top-right-radius: 0
}
.rbc-event-allday {
    background: #18bc9c;
    color: white;
    border-left: 0;
    padding-left: 1rem;
}
.rbc-row {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: row;
    flex-direction: row;
}
.rbc-row-segment {
    padding: 0;
    margin-bottom: .2rem;
}
.rbc-selected-cell {
    background-color: rgba(0,0,0,.1)
}
.rbc-show-more {
    background-color: hsla(0,0%,100%,.3);
    z-index: 4;
    font-weight: 700;
    font-size: 85%;
    height: auto;
    line-height: normal;
    white-space: nowrap;
    color: #18BC9C;
    height: 100%;
    margin-left: .9rem;
}
.rbc-month-view {
    position: relative;
    border: 0;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex: 1 0 0px;
    flex: 1 0;
    width: 100%;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-user-select: none;
    height: 100%
}
.rbc-month-view .rbc-header {
    border-bottom: 1px solid #ddd;
    border-top: 1px solid #ddd;
}
.rbc-month-view .rbc-header+.rbc-header {
    border-left: 1px solid #ddd;
}
.rbc-rtl .rbc-month-view .rbc-header+.rbc-header {
    border-left-width: 0;
    border-right: 1px solid #ddd;
}
.rbc-header-day {
    font-weight: 100;
    margin-bottom: 0;
}
.rbc-header-date {
    font-size: 2.5rem;
    font-weight: 100;
}
.rbc-month-header {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: row;
    flex-direction: row
}
.rbc-month-row {
    display: -ms-flexbox;
    display: flex;
    position: relative;
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex: 1 0 0px;
    flex: 1 0;
    overflow: hidden;
    height: 100%
}
.rbc-month-row+.rbc-month-row {
    border-top: 1px solid #ddd
}
.rbc-date-cell {
    padding: .5rem 1rem;
    font-size: 1.2rem;
}
.rbc-date-cell.rbc-now {
    font-weight: 700
}
.rbc-date-cell>a, .rbc-date-cell>a:active, .rbc-date-cell>a:visited {
    color: inherit;
    text-decoration: none
}
.rbc-row-bg {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex: 1 0 0px;
    flex: 1 0;
    overflow: hidden
}
.rbc-day-bg {
    border-left: 1px solid #ddd
}
.rbc-rtl .rbc-day-bg+.rbc-day-bg {
    border-left-width: 0;
    border-right: 1px solid #ddd
}
.rbc-overlay {
    position: absolute;
    z-index: 5;
    border: 1px solid #e5e5e5;
    background-color: #fff;
    -webkit-box-shadow: 0 5px 15px rgba(0,0,0,.25);
    box-shadow: 0 5px 15px rgba(0,0,0,.25);
    padding: 10px
}
.rbc-overlay>*+* {
    margin-top: 1px
}
.rbc-overlay-header {
    border-bottom: 1px solid #e5e5e5;
    margin: -10px -10px 5px;
    padding: 2px 10px
}
.rbc-agenda-view {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex: 1 0 0px;
    flex: 1 0;
    overflow: auto
}
.rbc-agenda-view table {
    width: 100%;
    border: 1px solid #ddd
}
.rbc-agenda-view table tbody>tr>td {
    padding: 5px 10px;
    vertical-align: top
}
.rbc-agenda-view table .rbc-agenda-time-cell {
    padding-left: 15px;
    padding-right: 15px;
    text-transform: lowercase
}
.rbc-agenda-view table tbody>tr>td+td {
    border-left: 1px solid #ddd
}
.rbc-rtl .rbc-agenda-view table tbody>tr>td+td {
    border-left-width: 0;
    border-right: 1px solid #ddd
}
.rbc-agenda-view table tbody>tr+tr {
    border-top: 1px solid #ddd
}
.rbc-agenda-view table thead>tr>th {
    padding: 3px 5px;
    text-align: left;
    border-bottom: 1px solid #ddd
}
.rbc-rtl .rbc-agenda-view table thead>tr>th {
    text-align: right
}
.rbc-agenda-time-cell {
    text-transform: lowercase
}
.rbc-agenda-time-cell .rbc-continues-after:after {
    content: " \BB"
}
.rbc-agenda-time-cell .rbc-continues-prior:before {
    content: "\AB   "
}
.rbc-agenda-date-cell, .rbc-agenda-time-cell {
    white-space: nowrap
}
.rbc-agenda-event-cell {
    width: 100%
}
.rbc-time-column {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    min-height: 100%
}
.rbc-time-column .rbc-timeslot-group {
    -ms-flex: 1;
    flex: 1 1
}
.rbc-timeslot-group {
    border-bottom: 1px solid #ddd;
    min-height: 40px;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-flow: column nowrap;
    flex-flow: column nowrap
}
.rbc-header-gutter, .rbc-time-gutter {
    -ms-flex: none;
    flex: none
}
.rbc-time-gutter .rbc-timeslot-group {
    border-left: 0;
}
.rbc-label {
    padding: 0 1rem;
    width: 100px;
    font-size: 1rem;
    color: rgba(0,0,0,.6);
}
.rbc-day-slot {
    position: relative
}
.rbc-day-slot .rbc-event {
    border-left: 5px solid #18BC9C;
    display: -ms-flexbox;
    display: flex;
    max-height: 100%;
    -ms-flex-flow: column wrap;
    flex-flow: column wrap;
    -ms-flex-align: start;
    align-items: flex-start;
    overflow: hidden;
    border-bottom: 1px solid #95a5a6;
    border-top: 1px solid #95a5a6;
    border-right: 1px Solid #95a5a6;
    padding: 0 10px;
    top: 57.1429%;
    height: 7.14286%;
    left: 0%;
    width: 100%;
    background: #fff ;
    border-radius: 0;
    color: rgba(0,0,0,.6);
}
.rbc-day-slot .rbc-event-label {
    -ms-flex: none;
    flex: none;
    padding-right: 5px;
    width: auto
}
.rbc-day-slot .rbc-event-content {
    width: 100%;
    -ms-flex: 1 1 0px;
    flex: 1 1;
    word-wrap: break-word;
    line-height: 1.4285em;
    height: 100%;
    min-height: 1em;
    font-size: 13.2px;
    font-weight: bold;
    color: #000;
}
.rbc-day-slot .rbc-time-slot {
    border-top: 1px solid #f7f7f7
}
.rbc-time-slot {
    -ms-flex: 1 0 0px;
    flex: 1 0
}
.rbc-time-slot.rbc-now {
    font-weight: 700
}
.rbc-day-header {
    text-align: center
}
.rbc-day-slot .rbc-event {
    position: absolute;
    z-index: 2
}
.rbc-slot-selection {
    z-index: 10;
    position: absolute;
    cursor: default;
    background-color: rgba(0,0,0,.5);
    color: #fff;
    font-size: 75%;
    padding: 3px
}
.rbc-time-view {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex: 1;
    flex: 1 1;
    width: 100%;
    border: 0;
    min-height: 0
}
.rbc-time-view .rbc-time-gutter {
    white-space: nowrap
}
.rbc-time-view .rbc-allday-cell {
    width: 100%;
    position: relative
}
.rbc-time-view .rbc-allday-events {
    position: relative;
    z-index: 4
}
.rbc-time-view .rbc-row {
    min-height: 20px
}
.rbc-time-header {
    display: -ms-flexbox;
    display: flex;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    -ms-flex-direction: column;
    flex-direction: column
}
.rbc-time-header.rbc-overflowing {
    border-right: 1px solid #ddd
}
.rbc-rtl .rbc-time-header.rbc-overflowing {
    border-right-width: 0;
    border-left: 1px solid #ddd
}
.rbc-time-header>.rbc-row>*+* {
    border-left: 1px solid #ddd;;
}
.rbc-time-header>.rbc-row .rbc-allday-cell {
    border-left: 0;
}
.rbc-rtl .rbc-time-header>.rbc-row>*+* {
    border-left-width: 0;
    border-right: 1px solid #ddd
}
.rbc-time-header>.rbc-row:first-child {
    border-bottom: 1px solid #ddd;
    background: #efefef;
    border-top: 1px solid #ddd;
}
.rbc-time-header .rbc-gutter-cell {
    -ms-flex: none;
    flex: none
}
.rbc-time-header>.rbc-gutter-cell+* {
    width: 100%
}
.rbc-time-content {
    display: -ms-flexbox;
    display: flex;
    -ms-flex: 1 0 0%;
    flex: 1 0;
    -ms-flex-align: start;
    align-items: flex-start;
    width: 100%;
    border-top: 1px solid #ddd;
    overflow-y: auto;
    position: relative
}
.rbc-time-content>.rbc-time-gutter {
    -ms-flex: none;
    flex: none
}
.rbc-time-content>*+*>* {
    border-left: 1px solid #ddd
}
.rbc-rtl .rbc-time-content>*+*>* {
    border-left-width: 0;
    border-right: 1px solid #ddd
}
.rbc-time-content>.rbc-day-slot {
    width: 100%;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-user-select: none
}
.rbc-current-time-indicator {
    position: absolute;
    z-index: 1;
    left: 0;
    height: 1px;
    background-color: #18BC9C;
    pointer-events: none
}
.rbc-current-time-indicator:before {
    display: block;
    position: absolute;
    left: -3px;
    top: -3px;
    content: " ";
    background-color: #18BC9C;
    border-radius: 50%;
    width: 8px;
    height: 8px
}
.rbc-rtl .rbc-current-time-indicator:before {
    left: 0;
    right: -3px
}


.rw-btn, .rw-filter-input, .rw-input, .rw-input-reset {
    color: inherit;
    padding: 0;
    margin: 0;
    border: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    background: none;
    background-image: none;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
    -ms-touch-action: manipulation;
    touch-action: manipulation
}
.rw-btn::-moz-focus-inner {
padding:0;
border:0
}
select.rw-input {
    text-transform: none
}
html input[type=button].rw-input {
    -webkit-appearance: button;
    cursor: pointer
}
textarea.rw-input {
    overflow: auto;
    resize: vertical
}
button[disabled].rw-input, fieldset[disabled] .rw-input, html input[disabled].rw-input {
    cursor: not-allowed
}
button.rw-input::-moz-focus-inner, input.rw-input::-moz-focus-inner {
border:0;
padding:0
}
input[type=checkbox], input[type=radio] {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    padding: 0
}
@font-face {
    font-family: RwWidgets;
    font-weight: 400;
    font-style: normal;
    src: url(/static/media/rw-widgets.bc7c4a59.eot);
    src: url(/static/media/rw-widgets.bc7c4a59.eot?#iefix&v=4.1.0) format("embedded-opentype"), url(/static/media/rw-widgets.12f0820c.woff) format("woff"), url(/static/media/rw-widgets.eceddf47.ttf) format("truetype"), url(/static/media/rw-widgets.792dcd18.svg#fontawesomeregular) format("svg")
}
.rw-i {
    display: inline-block;
    color: inherit;
    font-family: RwWidgets;
    font-style: normal;
    font-weight: 400;
    font-variant: normal;
    text-transform: none;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased
}
.rw-i-caret-down:before {
    content: "\E803"
}
.rw-i-caret-up:before {
    content: "\E800"
}
.rw-i-chevron-left:before {
    content: "\F104"
}
.rw-i-chevron-right:before {
    content: "\F105"
}
.rw-i-clock-o:before {
    content: "\E805"
}
.rw-i-calendar:before {
    content: "\E804"
}
.rw-i-search:before {
    content: "\E801"
}
.rw-btn {
    position: relative;
    color: #333;
    display: inline-block;
    text-align: center;
    vertical-align: middle;
    border: 1px solid transparent;
    cursor: pointer;
    outline: none
}
.rw-state-disabled .rw-btn, .rw-state-readonly .rw-btn {
    cursor: not-allowed
}
.rw-btn-select {
    opacity: .75;
    filter: alpha(opacity=75);
    -webkit-transition: opacity .15s ease-in;
    -o-transition: opacity .15s ease-in;
    transition: opacity .15s ease-in
}
.rw-btn-select:hover, .rw-state-focus .rw-btn-select, :hover>.rw-btn-select {
    opacity: 1;
    filter: alpha(opacity=100)
}
.rw-btn-primary {
    width: 100%;
    white-space: normal;
    line-height: 2em
}
.rw-btn-primary:hover {
    background-color: #e6e6e6
}
.rw-btn-primary[disabled], .rw-btn-select[disabled], fieldset[disabled] .rw-btn-primary, fieldset[disabled] .rw-btn-select {
    -webkit-box-shadow: none;
    box-shadow: none;
    cursor: not-allowed;
    opacity: .65;
    filter: alpha(opacity=65);
    pointer-events: none
}
.rw-sr {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0,0,0,0);
    border: 0
}
.rw-widget {
    background-clip: border-box;
    border: none;
    color: #333;
    font-size: 1em;
    font-family: inherit;
    outline: none;
    position: relative
}
.rw-widget, .rw-widget *, .rw-widget:after, .rw-widget :after, .rw-widget:before, .rw-widget :before {
    -webkit-box-sizing: border-box;
    box-sizing: border-box
}
.rw-widget>.rw-widget-container {
    width: 100%;
    margin: 0
}
.rw-widget-container {
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 4px
}
.rw-state-focus>.rw-widget-container, .rw-state-focus>.rw-widget-container:hover, .rw-widget-container.rw-state-focus, .rw-widget-container.rw-state-focus:hover {
    -webkit-box-shadow: 0 0 8px rgba(102,175,233,.6);
    box-shadow: 0 0 8px rgba(102,175,233,.6);
    border-color: #66afe9
}
.rw-state-readonly>.rw-widget-container, .rw-widget-container.rw-state-readonly {
    cursor: not-allowed
}
.rw-state-disabled>.rw-widget-container, .rw-state-disabled>.rw-widget-container:active, .rw-state-disabled>.rw-widget-container:hover, .rw-widget-container.rw-state-disabled, .rw-widget-container.rw-state-disabled:active, .rw-widget-container.rw-state-disabled:hover, fieldset[disabled] .rw-widget-container, fieldset[disabled] .rw-widget-container:active, fieldset[disabled] .rw-widget-container:hover {
    -webkit-box-shadow: none;
    box-shadow: none;
    cursor: not-allowed
}
.rw-widget-picker {
    position: relative;
    overflow: hidden;
    border-collapse: separate;
    display: inline-table;
    height: 2.429em
}
.rw-widget-picker>* {
    position: relative;
    border: none;
    outline: none;
    width: 100%;
    height: 100%;
    display: table-cell
}
.rw-widget-picker>.rw-select {
    width: 1%;
    white-space: nowrap
}
.rw-open>.rw-widget-picker {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0
}
.rw-open-up>.rw-widget-picker {
    border-top-right-radius: 0;
    border-top-left-radius: 0
}
.rw-state-disabled>.rw-widget-picker, fieldset[disabled] .rw-widget-picker {
    background-color: #eee
}
.rw-select {
    cursor: pointer
}
.rw-select>* {
    width: 1.9em;
    height: 100%
}
.rw-state-disabled .rw-select, .rw-state-readonly .rw-select {
    cursor: not-allowed
}
.rw-select-bordered {
    cursor: pointer;
    border: none;
    border-left: 1px solid #ccc
}
.rw-select-bordered:active, .rw-select-bordered:hover {
    background-color: #e6e6e6
}
.rw-select-bordered:active {
    -webkit-box-shadow: inset 0 3px 5px rgba(0,0,0,.125);
    box-shadow: inset 0 3px 5px rgba(0,0,0,.125)
}
.rw-state-disabled .rw-select-bordered, .rw-state-disabled .rw-select-bordered:active, .rw-state-disabled .rw-select-bordered:hover, .rw-state-readonly .rw-select-bordered, .rw-state-readonly .rw-select-bordered:active, .rw-state-readonly .rw-select-bordered:hover, fieldset[disabled] .rw-select-bordered, fieldset[disabled] .rw-select-bordered:active, fieldset[disabled] .rw-select-bordered:hover {
    cursor: not-allowed;
    background-color: inherit;
    background-image: none;
    -webkit-box-shadow: none;
    box-shadow: none
}
.rw-rtl .rw-select-bordered {
    border-right: 1px solid #ccc;
    border-left: none
}
.rw-rtl {
    direction: rtl
}
.rw-filter-input, .rw-input, .rw-input-reset {
    outline: 0
}
.rw-input-reset::-moz-placeholder {
color:#999;
opacity:1
}
.rw-input-reset:-ms-input-placeholder {
color:#999
}
.rw-input-reset::-webkit-input-placeholder {
color:#999
}
.rw-filter-input, .rw-input {
    color: #555;
    padding: 0 .857em;
    background-color: #fff
}
.rw-input[type=text]::-ms-clear {
display:none
}
.rw-input[disabled], fieldset[disabled] .rw-input {
    -webkit-box-shadow: none;
    box-shadow: none;
    cursor: not-allowed;
    opacity: 1;
    background-color: #eee;
    border-color: #ccc
}
.rw-input[readonly] {
    cursor: not-allowed
}
.rw-i.rw-loading {
    display: block;
    background: url(data:image/gif;base64,R0lGODlhEAAQAPIAAP///zMzM87OzmdnZzMzM4GBgZqamqenpyH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==) no-repeat 50%;
    min-width: 16px;
    width: 1.9em;
    height: 16px
}
.rw-i.rw-loading:before {
    content: ""
}
.rw-placeholder {
    color: #999
}
.rw-filter-input, .rw-widget-input {
    -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
    box-shadow: inset 0 1px 1px rgba(0,0,0,.075)
}
.rw-widget-input.rw-state-focus {
    -webkit-box-shadow: 0 0 8px rgba(102,175,233,.6), inset 0 1px 1px rgba(0,0,0,.075);
    box-shadow: 0 0 8px rgba(102,175,233,.6), inset 0 1px 1px rgba(0,0,0,.075)
}
.rw-list {
    margin: 0;
    padding: 0;
    list-style: none;
    font-size: 1em;
    outline: 0;
    overflow: auto;
    max-height: 200px
}
.rw-list-option {
    -ms-user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    color: #333;
    cursor: pointer;
    border: 1px solid transparent
}
.rw-list-option.rw-state-focus, .rw-list-option.rw-state-focus:hover {
    background-color: transparent;
    border: 1px solid #66afe9;
    color: #333
}
.rw-list-option:hover, .rw-list-option:hover.rw-state-focus {
    background-color: #e6e6e6;
    border: 1px solid #e6e6e6;
    color: #333
}
.rw-list-option.rw-state-selected, .rw-list-option.rw-state-selected:hover {
    background-color: #337ab7;
    border: 1px solid #337ab7;
    color: #fff
}
.rw-list-option.rw-state-disabled, .rw-list-option.rw-state-readonly, fieldset[disabled] .rw-list-option {
    -webkit-box-shadow: none;
    box-shadow: none;
    cursor: not-allowed;
    color: #999;
    filter: alpha(opacity=7);
    opacity: .7
}
.rw-list-option.rw-state-disabled:hover, .rw-list-option.rw-state-readonly:hover, fieldset[disabled] .rw-list-option:hover {
    background: none;
    border-color: transparent
}
.rw-list-empty, .rw-list-optgroup, .rw-list-option {
    padding: .143em .75em;
    outline: 0
}
.rw-list-optgroup {
    font-weight: 700;
    padding-top: 7px
}
.rw-list-option-create {
    border-top: 1px solid #ccc
}
.rw-dropdown-list-input {
    background-color: transparent;
    vertical-align: middle;
    padding-right: 0;
    max-width: 1px;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden
}
.rw-rtl .rw-dropdown-list-input {
    padding-right: .857em;
    padding-left: 0
}
.rw-filter-input {
    position: relative;
    margin: 4px;
    padding-right: 0
}
.rw-filter-input .rw-rtl {
    padding-right: .857em;
    padding-left: 0
}
.rw-filter-input .rw-btn, .rw-filter-input .rw-select {
    opacity: .75;
    filter: alpha(opacity=75);
    cursor: text
}
.rw-filter-input>.rw-select, .rw-filter-input>.rw-select:active, .rw-filter-input>.rw-select:hover {
    background: none;
    cursor: auto;
    -webkit-box-shadow: none;
    box-shadow: none
}
.rw-number-picker .rw-btn {
    cursor: pointer;
    height: calc(1.2145em - 1px);
    margin-top: -1px\9;
    height: 1.2145em\9;
    line-height: 1.2145em;
    line-height: calc(1.2145em - 1px);
    display: block;
    border: none
}
.rw-number-picker .rw-btn:active, .rw-number-picker .rw-btn:hover {
    background-color: #e6e6e6
}
.rw-number-picker .rw-btn:active {
    -webkit-box-shadow: inset 0 3px 5px rgba(0,0,0,.125);
    box-shadow: inset 0 3px 5px rgba(0,0,0,.125)
}
.rw-state-disabled .rw-number-picker .rw-btn, .rw-state-disabled .rw-number-picker .rw-btn:active, .rw-state-disabled .rw-number-picker .rw-btn:hover, .rw-state-readonly .rw-number-picker .rw-btn, .rw-state-readonly .rw-number-picker .rw-btn:active, .rw-state-readonly .rw-number-picker .rw-btn:hover, fieldset[disabled] .rw-number-picker .rw-btn, fieldset[disabled] .rw-number-picker .rw-btn:active, fieldset[disabled] .rw-number-picker .rw-btn:hover {
    cursor: not-allowed;
    background-color: inherit;
    background-image: none;
    -webkit-box-shadow: none;
    box-shadow: none
}
.rw-number-picker .rw-select {
    vertical-align: middle
}
.rw-number-picker .rw-select, .rw-number-picker .rw-select:active, .rw-number-picker .rw-select:hover {
    -webkit-box-shadow: none;
    box-shadow: none
}
.rw-calendar-popup {
    right: auto;
    min-width: 0;
    width: 18em
}
.rw-calendar {
    border-radius: 4px;
    background-color: #fff;
    border: 1px solid #ccc;
    overflow: hidden
}
.rw-calendar.rw-popup {
    border-color: #ccc
}
.rw-calendar-now {
    font-weight: 700
}
.rw-calendar-btn-left, .rw-calendar-btn-right {
    width: 12.5%
}
.rw-calendar-btn-view {
    width: 75%
}
.rw-calendar-footer {
    border-top: 1px solid #ccc
}
.rw-calendar-grid {
    outline: none;
    height: 14.28571429em;
    table-layout: fixed;
    border-collapse: separate;
    border-spacing: 0;
    width: 100%;
    background-color: #fff
}
.rw-calendar-grid th {
    border-bottom: 1px solid #ccc
}
.rw-calendar-grid th, .rw-cell {
    text-align: center;
    padding: .25em
}
.rw-cell {
    color: #333;
    border-radius: 4px;
    cursor: pointer;
    line-height: normal;
    border: 1px solid transparent
}
.rw-cell:hover {
    background-color: #e6e6e6;
    border: 1px solid #e6e6e6;
    color: #333
}
.rw-cell.rw-state-focus, .rw-cell.rw-state-focus:hover {
    background-color: transparent;
    border: 1px solid #66afe9;
    color: #333
}
.rw-cell.rw-state-selected, .rw-cell.rw-state-selected:hover {
    background-color: #337ab7;
    border: 1px solid #337ab7;
    color: #fff
}
.rw-cell.rw-state-disabled {
    color: #999;
    filter: alpha(opacity=7);
    opacity: .7
}
.rw-cell.rw-state-disabled:hover {
    background: none;
    border-color: transparent
}
.rw-calendar-month .rw-cell {
    text-align: center
}
.rw-cell-off-range {
    color: #999
}
.rw-calendar-transition-group {
    position: relative
}
.rw-calendar-transition {
    -webkit-transition: -webkit-transform .3s;
    transition: -webkit-transform .3s;
    -o-transition: transform .3s;
    transition: transform .3s;
    transition: transform .3s, -webkit-transform .3s;
    overflow: hidden
}
.rw-calendar-transition-top {
    -ms-transform: translateY(-100%);
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%)
}
.rw-calendar-transition-bottom {
    -ms-transform: translateY(100%);
    -webkit-transform: translateY(100%);
    transform: translateY(100%)
}
.rw-calendar-transition-right {
    -ms-transform: translateX(-100%);
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%)
}
.rw-calendar-transition-left {
    -ms-transform: translateX(100%);
    -webkit-transform: translateX(100%);
    transform: translateX(100%)
}
.rw-calendar-transition-entered.rw-calendar-transition-bottom, .rw-calendar-transition-entered.rw-calendar-transition-top, .rw-calendar-transition-entering.rw-calendar-transition-bottom, .rw-calendar-transition-entering.rw-calendar-transition-top {
    -ms-transform: translateY(0);
    -webkit-transform: translateY(0);
    transform: translateY(0)
}
.rw-calendar-transition-entered.rw-calendar-transition-left, .rw-calendar-transition-entered.rw-calendar-transition-right, .rw-calendar-transition-entering.rw-calendar-transition-left, .rw-calendar-transition-entering.rw-calendar-transition-right {
    -ms-transform: translateX(0);
    -webkit-transform: translateX(0);
    transform: translateX(0)
}
.rw-calendar-transition-exiting.rw-calendar-transition-top {
    -ms-transform: translateY(100%);
    -webkit-transform: translateY(100%);
    transform: translateY(100%)
}
.rw-calendar-transition-exiting.rw-calendar-transition-bottom {
    -ms-transform: translateY(-100%);
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%)
}
.rw-calendar-transition-exiting.rw-calendar-transition-right {
    -ms-transform: translateX(100%);
    -webkit-transform: translateX(100%);
    transform: translateX(100%)
}
.rw-calendar-transition-exiting.rw-calendar-transition-left {
    -ms-transform: translateX(-100%);
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%)
}
.rw-select-list {
    overflow: auto
}
.rw-select-list .rw-list {
    max-height: none;
    font-size: 1em
}
.rw-select-list-label {
    display: block;
    position: relative;
    font-weight: 400;
    cursor: inherit;
    padding-left: 20px;
    margin: 0
}
.rw-rtl .rw-select-list-label {
    padding-left: 0;
    padding-right: 20px
}
input.rw-select-list-input {
    position: absolute;
    left: 0;
    top: 50%;
    -ms-transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    top: .1em/9;
    margin: 0;
    line-height: normal;
    cursor: inherit
}
.rw-rtl input.rw-select-list-input {
    left: auto;
    right: 0
}
.rw-loading-mask {
    position: relative;
    min-height: 50px;
    border-radius: 4px
}
.rw-loading-mask:after {
    content: "";
    background: url(/static/media/loader-big.a770b679.gif) no-repeat 50%;
    position: absolute;
    background-color: #fff;
    opacity: .7;
    filter: alpha(opacity=70);
    top: 0;
    left: 0;
    height: 100%;
    width: 100%
}
.rw-multiselect {
    background-color: #fff;
    border-color: #e6e6e6;
    cursor: text
}
.rw-multiselect .rw-input-reset {
    height: calc(2.429em - 2px);
    margin-top: -2px\9;
    height: 2.429em\9;
    border-width: 0;
    width: auto;
    max-width: 100%;
    padding: 0 .857em
}
.rw-multiselect .rw-select, .rw-multiselect .rw-select:active, .rw-multiselect .rw-select:hover {
    -webkit-box-shadow: none;
    box-shadow: none;
    background: none
}
.rw-multiselect-taglist {
    margin: 0;
    padding: 0;
    list-style: none;
    display: inline;
    outline: none
}
.rw-multiselect-tag {
    display: inline-table;
    color: inherit;
    padding: 0 .35em;
    margin-left: calc(.279335em - 1px);
    margin-top: .279335em;
    margin-top: calc(.279335em - 1px);
    height: 1.87033em;
    border-radius: 3px;
    background-color: #eee;
    border: 1px solid #ccc;
    cursor: default;
    vertical-align: top;
    text-align: center;
    overflow: hidden;
    max-width: 100%
}
.rw-multiselect-tag>* {
    display: table-cell;
    vertical-align: middle;
    height: 100%
}
.rw-rtl .rw-multiselect-tag {
    margin-left: 0;
    margin-right: calc(.279335em - 1px);
    padding: 0 .35em
}
.rw-multiselect-tag.rw-state-focus, .rw-multiselect-tag.rw-state-focus:hover {
    background-color: transparent;
    border: 1px solid #66afe9;
    color: #333
}
.rw-multiselect-tag.rw-state-disabled, .rw-multiselect-tag.rw-state-readonly, .rw-state-disabled .rw-multiselect-tag, .rw-state-readonly .rw-multiselect-tag {
    cursor: not-allowed
}
.rw-multiselect-tag.rw-state-disabled, .rw-state-disabled .rw-multiselect-tag, fieldset[disabled] .rw-multiselect-tag {
    opacity: .65;
    filter: alpha(opacity=65)
}
fieldset[disabled] .rw-multiselect-tag {
    -webkit-box-shadow: none;
    box-shadow: none;
    cursor: not-allowed
}
.rw-multiselect-tag-btn {
    color: inherit;
    margin-left: .25em
}
.rw-rtl .rw-multiselect-tag-btn {
    margin-left: 0;
    margin-right: .25em
}
.rw-autocomplete .rw-select {
    position: absolute;
    display: block;
    width: auto;
    top: 0;
    bottom: 0;
    right: 0
}
.rw-popup-container {
    position: absolute;
    z-index: 1005;
    top: 100%;
    left: -6px;
    right: -6px
}
.rw-popup-container.rw-dropup {
    top: auto;
    bottom: 100%
}
.rw-state-focus .rw-popup-container {
    z-index: 1006
}
.rw-popup-transition {
    width: 100%;
    margin-bottom: 6px;
    padding: 0 6px
}
.rw-dropup>.rw-popup-transition {
    margin-bottom: 0;
    margin-top: 6px
}
.rw-popup {
    border-top-right-radius: 0;
    border-top-left-radius: 0;
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
    -webkit-box-shadow: 0 5px 6px rgba(0,0,0,.2);
    box-shadow: 0 5px 6px rgba(0,0,0,.2);
    border: 1px solid #ccc;
    background: #fff
}
.rw-dropup .rw-popup {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    border-top-right-radius: 3px;
    border-top-left-radius: 3px;
    -webkit-box-shadow: 0 -2px 6px rgba(0,0,0,.2);
    box-shadow: 0 -2px 6px rgba(0,0,0,.2)
}
.rw-popup-transition {
    -webkit-transition: -webkit-transform .2s;
    transition: -webkit-transform .2s;
    -o-transition: transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s
}
.rw-popup-transition-entering {
    overflow: hidden
}
.rw-popup-transition-entering .rw-popup-transition {
    -ms-transform: translateY(0);
    -webkit-transform: translateY(0);
    transform: translateY(0);
    -webkit-transition-timing-function: ease-out;
    -o-transition-timing-function: ease-out;
    transition-timing-function: ease-out
}
.rw-popup-transition-exiting .rw-popup-transition {
    -webkit-transition-timing-function: ease-in;
    -o-transition-timing-function: ease-in;
    transition-timing-function: ease-in
}
.rw-popup-transition-exited, .rw-popup-transition-exiting {
    overflow: hidden
}
.rw-popup-transition-exited .rw-popup-transition, .rw-popup-transition-exiting .rw-popup-transition {
    -ms-transform: translateY(-100%);
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%)
}
.rw-popup-transition-exited.rw-dropup .rw-popup-transition, .rw-popup-transition-exiting.rw-dropup .rw-popup-transition {
    -ms-transform: translateY(100%);
    -webkit-transform: translateY(100%);
    transform: translateY(100%)
}
.rw-popup-transition-exited {
    display: none
}
.rw-state-disabled {
    -webkit-box-shadow: none;
    box-shadow: none;
    cursor: not-allowed
}
.colorPicker:hover {
    border: 5px solid #3f3e3e
}
.colorPicker {
    border-radius: 15px;
    width: 30px;
    height: 30px;
    border: 1px solid #aaa
}
.colorPickerSelected {
    border-radius: 15px;
    width: 30px;
    height: 30px;
    border: 4px solid #000
}
.colorPicker_yellow {
    background-color: #F39C12
}
.colorPicker_orange {
    background-color: #f26202
}
.colorPicker_red {
    background-color: #E74C3C
}
.colorPicker_olive {
    background-color: #b5cc18
}
.colorPicker_green {
    background-color: #18BC9C
}
.colorPicker_teal {
    background-color: #18BC9C
}
.colorPicker_blue {
    background-color: #2384c6
}
.colorPicker_violet {
    background-color: #bc188a
}
.colorPicker_purple {
    background-color: #a333c8
}
.colorPicker_pink {
    background-color: #e03997
}
.colorPicker_brown {
    background-color: #a5673f
}
.colorPicker_grey {
    background-color: #767676
}

.calendar_event_cell_request {
	background: #cce5ff !important;
    color: #004085;
    border-left: .3125rem solid #004085!important;
}

.calendar_event_cell_request .rbc-event-label {
    color: #00827c;
    font-weight: bold;
}



.calendar_event_cell_google {
    background: #e6e8ff !important;
color: #999;
border-left: 5px solid #468cfc !important;
}

.calendar_event_cell_google .rbc-event-label {
color: #000b82;
font-weight: bold;
}

.calendar_event_cell_microsoft {
    background: #e6e8ff !important;
color: #999;
border-left: 5px solid #468cfc !important;
}

.calendar_event_cell_microsoft .rbc-event-label {
color: #000b82;
font-weight: bold;
}


.rbc-event-content {
    width: 100%;
}
.event-custom-wrapper {
    display: flex;
    align-items: center;
    width: 100%;
}
.event-custom-wrapper {
    display: flex;
    align-items: center;
    width: 1.6rem;
    justify-content: center
}
.event-custom-wrapper .event-dot {
    display: block;
    width: .5rem;
    height: .5rem;
    background: #18bc9c;
    border-radius: 50%;
}
.event-custom-wrapper i {
    margin-right: 0;
}
.event-custom-wrapper.google .event-dot {
    background: #4285f4;
}

@media (max-width: 768px) {
    .rbc-header-day, .rbc-header-date {
        font-size: 1rem;
    }
}