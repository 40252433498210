.selection-month {
  width: 100%;
}
.selection-month .rbc-row-content {
  min-height: 50px !important;
}
.selection-month-toolbar {
  display: flex;
  justify-content: space-between;
  font-size: 1.2rem;
  padding-bottom: 1rem;
  user-select: none;
}
.selection-month-toolbar i:hover {
  cursor: pointer;
}

.selection-month .rbc-row-content {
  cursor: pointer;
}
.selection-month .rbc-header {
  padding: .5rem;
}
.selection-month .rbc-month-view {
  border: 1px solid #ddd;
  border-top: 0;
}