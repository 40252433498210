#container-MIC {
  width: 400px;
  max-width: 100%;
  backface-visibility: visisble;
  overflow-x: visible !important;
  overflow-y: visible !important;
}
#pushable-main {
  min-height: 100vh;
  overflow-x: visible;
  overflow-y: visible;
}
#container-main {
  height: 100vh;
  display: flex;
  flex-direction: column;
}