.DayPicker {
    display: inline-block;
    font-size:1rem
}

.DayPicker-wrapper {
    position: relative;
    -ms-flex-direction: row;
    flex-direction: row;
    padding-bottom: 1em;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select:none
}

.DayPicker-Months {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -ms-flex-pack: center;
    justify-content:center
}

.DayPicker-Month {
    display: table;
    margin: 0 1em;
    margin-top: 1em;
    border-spacing: 0;
    border-collapse: collapse;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select:none
}

.DayPicker-NavButton {
    position: absolute;
    top: 1em;
    right: 1.5em;
    left: auto;
    display: inline-block;
    margin-top: 2px;
    width: 1.25em;
    height: 1.25em;
    background-position: 50%;
    background-size: 50%;
    background-repeat: no-repeat;
    color: #8b9898;
    cursor:pointer
}

.DayPicker-NavButton:hover {
    opacity:.8
}

.DayPicker-NavButton--prev {
    margin-right: 1.5em;
    background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAwCAYAAAB5R9gVAAAABGdBTUEAALGPC/xhBQAAAVVJREFUWAnN2G0KgjAYwPHpGfRkaZeqvgQaK+hY3SUHrk1YzNLay/OiEFp92I+/Mp2F2Mh2lLISWnflFjzH263RQjzMZ19wgs73ez0o1WmtW+dgA01VxrE3p6l2GLsnBy1VYQOtVSEH/atCCgqpQgKKqYIOiq2CBkqtggLKqQIKgqgCBjpJ2Y5CdJ+zrT9A7HHSTA1dxUdHgzCqJIEwq0SDsKsEg6iqBIEoq/wEcVRZBXFV+QJxV5mBtlDFB5VjYTaGZ2sf4R9PM7U9ZU+lLuaetPP/5Die3ToO1+u+MKtHs06qODB2zBnI/jBd4MPQm1VkY79Tb18gB+C62FdBFsZR6yeIo1YQiLJWMIiqVjQIu1YSCLNWFgijVjYIuhYYCKoWKAiiFgoopxYaKLUWOii2FgkophYp6F3r42W5A9s9OcgNvva8xQaysKXlFytoqdYmQH6tF3toSUo0INq9AAAAAElFTkSuQmCC")
}

.DayPicker-NavButton--next {
    background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAwCAYAAAB5R9gVAAAABGdBTUEAALGPC/xhBQAAAXRJREFUWAnN119ugjAcwPHWzJ1gnmxzB/BBE0n24m4xfNkTaOL7wOtsl3AXMMb+Vjaa1BG00N8fSEibPpAP3xAKKs2yjzTPH9RAjhEo9WzPr/Vm8zgE0+gXATAxxuxtqeJ9t5tIwv5AtQAApsfT6TPdbp+kUBcgVwvO51KqVhMkXKsVJFXrOkigVhCIs1Y4iKlWZxB1rX4gwlpRIIpa8SDkWmggrFq4IIRaJKCYWnSgnrXIQV1r8YD+1Vrn+bReagysIFfLABRt31v8oBu1xEBttfRbltmfjgEcWh9snUS2kNdBK6WN1vrOWxObWsz+fjxevsxmB1GQDfINWiev83nhaoiB/CoOU438oPrhXS0WpQ9xc1ZQWxWHqUYe0I0qrKCQKjygDlXIQV2r0IF6ViEBxVTBBSFUQQNhVYkHIVeJAtkNsbQ7c1LtzP6FsObhb2rCKv7NBIGoq4SDmKoEgTirXAcJVGkFSVVpgoSrXICGUMUH/QBZNSUy5XWUhwAAAABJRU5ErkJggg==")
}

.DayPicker-NavButton--interactionDisabled {
    display:none
}

.DayPicker-Caption {
    display: table-caption;
    margin-bottom: .5em;
    padding: 0 .5em;
    text-align:left
}

.DayPicker-Caption > div {
    font-weight: 500;
    font-size:1.15em
}

.DayPicker-Weekdays {
    display: table-header-group;
    margin-top:1em
}

.DayPicker-WeekdaysRow {
    display:table-row
}

.DayPicker-Weekday {
    display: table-cell;
    padding: .5em;
    color: #8b9898;
    text-align: center;
    font-size:.875em
}

.DayPicker-Weekday abbr[title] {
    border-bottom: none;
    text-decoration:none
}

.DayPicker-Body {
    display:table-row-group
}

.DayPicker-Week {
    display:table-row
}

.DayPicker-Day {
    border-radius: 50%;
    text-align:center
}

.DayPicker-Day, .DayPicker-WeekNumber {
    display: table-cell;
    padding: .5em;
    vertical-align: middle;
    cursor:pointer
}

.DayPicker-WeekNumber {
    min-width: 1em;
    border-right: 1px solid #eaecec;
    color: #8b9898;
    text-align: right;
    font-size:.75em
}

.DayPicker--interactionDisabled .DayPicker-Day {
    cursor:default
}

.DayPicker-Footer {
    padding-top:.5em
}

.DayPicker-TodayButton {
    border: none;
    background-color: transparent;
    background-image: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    color: #4a90e2;
    font-size: .875em;
    cursor:pointer
}

.DayPicker-Day--today {
    color: #d0021b;
    font-weight:700
}

.DayPicker-Day--outside {
    color: #8b9898;
    cursor:default
}

.DayPicker-Day--disabled {
    color: #dce0e0;
    cursor:default
}

.DayPicker-Day--sunday {
    background-color:#f7f8f8
}

.DayPicker-Day--sunday:not(.DayPicker-Day--today) {
    color:#dce0e0
}

.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
    position: relative;
    background-color: #4a90e2;
    color:#f0f8ff
}

.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside):hover {
    background-color:#51a0fa
}

.DayPicker:not(.DayPicker--interactionDisabled) .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):hover {
    background-color:#f0f8ff
}

.DayPickerInput {
    display:inline-block
}

.DayPickerInput-OverlayWrapper {
    position:relative
}

.DayPickerInput-Overlay {
    position: absolute;
    left: 0;
    z-index: 1;
    background: #fff;
    -webkit-box-shadow: 0 2px 5px rgba(0, 0, 0, .15);
    box-shadow:0 2px 5px rgba(0, 0, 0, .15)
}



.editor-class {
    border: 1px solid #F1F1F1;

}

.wrapper-class {
    height: 300px;
}


.rdt .form-control {
    background-color:#fff
}

.opacity-50 {
    opacity:.5
}

.app-navbar {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding: 1rem;
    background: #000;
    vertical-align: middle;
    font-size: .9rem;
    font-weight:400
}

@media screen and (max-width: 960px) {
    .app-header-github {
        margin-bottom:2rem
    }
}

.app-navbar a {
    opacity: 1;
    color:#fff
}

.app-navbar a, .app-navbar a:hover {
    -webkit-transition: .1s;
    -o-transition: .1s;
    transition:.1s
}

.app-navbar a:hover {
    opacity: .5;
    text-decoration:none
}

.app-navbar-ghlogo {
    height: 20px;
    width:20px
}

.app-header {
    background:#222
}

.app-desc, .app-header {
    padding: 1rem;
    color: #fff;
    text-align:center
}

.app-desc {
    background:#444
}

.app {
    margin-top:3rem
}

::-webkit-scrollbar {
    display:none
}



.rrule {
    background-color: #ffecef;
    font-size: 80%;
    font-family:SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace
}

.rrule-not-copied, .rrule-not-copied:focus {
    color:#222
}

.rrule-copied, .rrule-copied:focus {
    color: #888
}
  