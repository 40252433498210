
@import '../../../../node_modules/react-day-picker/lib/style.css';

.rdt .form-control {
  background-color: white;
}

.opacity-50 {
  opacity: 0.5;
}
