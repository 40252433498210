#mic_wrapper_closed {
    position: fixed;
    right: 40px;
    cursor: pointer;
    color: rgb(255, 255, 255);
    bottom: 0;
    display: flex;
    padding: 6px 12px;
    padding-left: 20px;
    font-size: 16px;
    z-index: 1;
    transition: .3s margin-bottom;
}
#mic_wrapper_icon {
    position: absolute;
    font-size: 24px;
    left: 6px;
    bottom: 6px;
    width: 50px;
    height: 50px;
    background: #18BC9C;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    border: 1px solid white;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 4px 8px 0px, rgba(0, 0, 0, 0.19) 0px 6px 20px 0px;
}
#mic_wrapper_icon>i {
  width: auto;
  height: auto;
  margin-right: 0;
}
#mic_wrapper_icon .label {
  border-radius: 50%;
}
#mic_wrapper_closed span:not(.stacked-icon-group) {
  display: none;
}



#mic_wrapper_open {
  box-shadow: rgba(0, 0, 0, 0.2) 0px 4px 8px 0px, rgba(0, 0, 0, 0.19) 0px 6px 20px 0px;
  width: 400px;
  max-width: 100%;
  height: 100vh;
  background-color: #18BC9C;
  border-left: none;
  display: flex;
  flex-direction: column;
  position: sticky;
  top: 0;
}
#mic_wrapper_header {
  color: rgb(255, 255, 255);
  padding: 0 8px;
}
#mic_wrapper_header i {
  user-select: none;
  cursor: pointer;
}
#mic_integration {
  flex: 1;
}
#mic_close_icon {
  font-size: 36px;
  color: rgb(255, 255, 255);
  
}

@media(min-width: 768px) {
  #mic_wrapper_closed {
    box-shadow: rgba(0, 0, 0, 0.2) 0px 4px 8px 0px, rgba(0, 0, 0, 0.19) 0px 6px 20px 0px;
    right: 40px;
    bottom: 0;
    display: flex;
    padding: 6px 12px;
    padding-left: 66px;
    border: 1px solid white;
    border-bottom: none;
    padding-left: 66px;
    background: #18BC9C;
  }
  #mic_wrapper_icon {
    box-shadow: none;
  }
  #mic_wrapper_closed span:not(.stacked-icon-group) {
    display: block;
  }
}